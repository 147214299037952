<template>
  <div>
    <loader :is-loading="salesRepresentatives.loading" />
    <lf-table
      v-if="!salesRepresentatives.loading"
      :columns="columns"
      :data="salesRepresentatives.data"
      :metadata="salesRepresentatives.meta"
      @change-page="changePage"
      @change-items-per-page="changePerPage"
    />
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import router from "@/router";

import { getParamsWithPagination } from "@/helpers/pagination";
import SalesRepActionsField from "./SalesRepActionsField.vue";
import { useUsersStore } from "@/stores/users";
import { storeToRefs } from "pinia";
import LfTable from "@/components/ui/table/LfTable.vue";

const { t } = useI18n();
const usersStore = useUsersStore();

const props = defineProps({
  clientId: {
    type: String,
    required: true
  },
  search: {
    type: String,
    default: ""
  }
});

const page = ref(router.currentRoute?.value?.query.page || 1);
const perPage = ref(router.currentRoute?.value?.query.per_page || 15);
const { usersData: salesRepresentatives } = storeToRefs(usersStore);

const getSalesRepresentatives = async () => {
  const data = {
    clientId: props.clientId,
    params: getParamsWithPagination(
      { search: props.search || null },
      page.value,
      perPage.value
    )
  };
  await router.push({ query: data.params });

  return usersStore.getSalesRepresentatives(data);
};

onMounted(getSalesRepresentatives);

onUnmounted(() => usersStore.unsetUsers());

watch(() => props.search, getSalesRepresentatives);

const changePage = (newPage) => {
  page.value = newPage;
  getSalesRepresentatives();
};

const changePerPage = (newPerPage) => {
  perPage.value = newPerPage;
  getSalesRepresentatives();
};

const columns = [
  {
    label: t("COMMON.USER"),
    component: "user-profile"
  },
  {
    label: t("COMMON.EMAIL"),
    key: "email"
  },
  {
    label: "",
    component: SalesRepActionsField
  }
];
</script>
